/* .footer{
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #797979;
    padding: 15px 0;
}
.footer ul li{
    list-style: none;
    display: inline-block;
    margin-left: 20px;
} */

/* ============== Media Querry ============ */
/* @media (max-width: 700px){
    .footer{
        display: block;
        text-align: center;
    }
    .footer ul li{
        margin: 10px;
    }
    .footer ul{
        margin-top: 10px;
    }
} */

/* Footer Styles */
.footer {
    /* background-color: #dee7f0; */
    /* color: white; */
    border-top: 1px solid #797979;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
    max-width: 250px;
  }
  
  .footer-section h4 {
    margin-bottom: 15px;
    font-size: 24px;
    color: rgb(0, 0, 0);
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin: 10px 0;
  }
  
  .footer-section ul li a {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .social-media a {
    display: block;
    margin: 5px 0;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }
  
  .social-media a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    margin-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }
  
    
  /* ------------ media query -------------------------- */
  @media (max-width: 900px) {
    .footer-section h4 {
      /* margin-bottom: 15px; */
      font-size: 14px;
      font-weight: 300;
      /* color: rgb(0, 0, 0); */

    }
  }