.hero{
    width: 100%;
    min-height: 60vh;
    /* background: linear-gradient(rgba(32, 7, 196, 0.3), rgba(135, 8, 110, 0.3), rgba(2, 98, 34, 0.7)), url("../../assets/home/image-2-students.jpg?v=2"); */
    background: linear-gradient(rgba(32, 7, 196, 0.3), rgba(135, 8, 110, 0.3), rgba(2, 98, 34, 0.7)), url("../../assets/dennis-images/imagr-1.jpg?v=2");
    /* background: linear-gradient(rgba(32, 7, 196, 0.3), rgba(135, 8, 110, 0.3), rgba(2, 98, 34, 0.7)), url("https://via.placeholder.com/1920x1080"); */
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero-text{
    text-align: center;
    max-width: 800px;
}
.hero-text h1{
    font-size: 40px;
    font-weight: 700;
}
.hero-text p{
    max-width: 700px;
    margin: 10px auto 20px;
    line-height: 1.4;
}
.more-gallery{
    margin-left: 20px;
}

/* ============== Media Querry ============ */
@media (max-width: 900px) {
    .hero-text h1{
        font-size: 20px;
        font-weight: 500;
    }
}

@media (max-width: 700px) {
    .hero-text h1{
        font-size: 20px;
        /* max-width: 400px; */
        margin: auto;
    }
    .hero-text p{
        font-size: 14px;
        margin: 15px auto 30px;
    }
}