@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Great+Vibes&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Great+Vibes&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Outfit:wght@100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: "Outfit", sans-serif; */
    font-family: "Kanit", sans-serif;
    font-weight: 300;
}
body{
    background: #ffffff;
}
a{
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
}
.container{
    padding-left: 10%;
    padding-right: 10%;
}
.btn{
    background: #FFF;
    color: #212121;
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn img{
    width: 20px;
    margin-left: 10px;
}
.btn.dark-btn{
    background: #212EA0;
    color: #fff;
}

/* ============== Media Querry ============ */
@media (max-width: 1250px){
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* font-family: "Outfit", sans-serif; */
        font-family: "Kanit", sans-serif;
        font-weight: 200;
        font-size: 12px;
    }
    .container{
        padding-left: 1%;
        padding-right: 1%;
    }
    .btn{
        padding: 8px 20px;
    }
}



/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
