.about {
    display: grid;
    padding: 133.3px 85.3px;
    align-items: center;
    gap: 114.6px;
    grid-template-columns: repeat(2, 1fr);
  }
  .about-img > img {
    width: 100%;
    /* height: 100%; */
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21.333px;
  }


  @media (max-width: 1200px) {
    .about {
        grid-template-columns: 1fr; /* Switch to single column layout */
        gap: 50px; /* Adjust gap if necessary for better spacing */
    }
    .about-img > img {
      width: 40%;
      /* height: 100%; */
    }

  }


