.resume-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100vh; /* Make the container take the full height of the viewport */
    box-sizing: border-box;
}

.resume-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.resume-iframe {
    width: 100%;
    height: calc(100vh - 60px); /* Adjust the height based on title and padding */
    border: none; /* Remove default iframe border */
}
