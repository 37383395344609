.contact{
    margin: 80px auto;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact-col{
    flex-basis: 48%;
    color: #676767;
}
.contact-col h3{
    color: #000F38;
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.contact-col h3 img{
    width: 35px;
    margin-left: 10px;

}
.contact-col p{
    max-width: 450px;
    line-height: 1.3;

}
.contact-col ul li{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.contact-col ul li img{
    width: 25px;
    margin-right: 10px;
}
.contact form input, .contact form textarea{
    display: block;
    width: 100%;
    background: #EBECFE;
    padding: 15px;
    border: 0;
    outline: 0;
    margin-bottom: 15px ;
    margin-top: 5px;
    resize: none;
}
.contact span{
    display: block;
    margin: 20px 0;
}

/* ============== Media Querry ============ */
@media (max-width: 800px){
    .contact{
        display: block;
    }
    .contact-col{
        padding: 20px;
    }
    .contact-col h3{
        font-size: 15px;
    }
    .contact-col img{
        max-height: 20px;  /* Set the max height */
        max-width: 70%;   /* Ensure the width scales automatically based on the aspect ratio */
        height: auto;      /* Allow the browser to calculate the height */
        width: auto;       /* Keep the width automatic for scaling */
        vertical-align: middle;
    }
}
