/* Global CSS */

:root {
    --primary: #5e3bee;
    --heading-color: #282938;
    --bg-shade: #f5fcff;
    --github: #e62872;
    --darkblue: #1c1e53;
    --black: #000000;
    --white: #ffffff;
  }

  /* My Portfolio Starts */
  .portfolio--section {
    display: flex;
    /* padding: 40px 20px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    /* background-color: rgba(0, 255, 255, 0.05); */
  }
  .portfolio--container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .portfolio--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21.3px;
  }
  .portfolio--container h2 {
    font-size: 30px;
    color: var(--github);
  }
  .portfolio--section--container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 10px;
    width: 100%;
  }
  .portfolio--section--img {
    border-radius: 8px;
    width: 100%;
  }
  .portfolio--section--img > img {
    width: 100%;
  }
  .portfolio--section--card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    /* padding: 50px; */
    flex: 1 0 0;
    background: #fff;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .portfolio--section--card--content {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }
  .portfolio--section--title {
    color: var(--darkblue);
    font-weight: 500;
    padding-bottom: 15px;
  }
  .portfolio--link {
    text-decoration: none;
    display: flex;
    gap: 16px;
    border-bottom: 1.33px solid var(--primary);
    padding-bottom: 10.6px;
    font-weight: 400;
  }
  .portfolio--section--card:hover path {
    stroke: #006b6a;
  }
  
  .list-items{
    padding: 20px;
  }
  .other-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    /* padding: 50px; */
    flex: 1 0 0;
    background: #fff;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .other-projects{
    padding: 20px;
    text-align: center;
    line-height: 1.5;
  }
  .btn-github {
    color: var(--white);
    background-color: var(--github);
    border: var(--github);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
    gap: 16px;
  }
  .btn-github:hover {
    color: var(--white);
    background-color: var(--primary);
  }
  .other-view:hover{
    background-color: rgba(16, 24, 40, 0.2);
  }

  @media (max-width: 900px) {
    .btn-outline-primary {
      display: none;
    }


    .portfolio--section--container,
    .portfolio--container-box {
      display: flex;
      flex-direction: column;
    }
    .portfolio--container {
      align-items: center;
    }
    .portfolio--container h2 {
      font-size: 20px;
      font-weight: 400;
      color: var(--github);
    }
    .portfolio--container-box {
      gap: 30px;
    }
    .portfolio--section--title {
      /* color: var(--darkblue); */
      font-weight: 500;
      font-size: 14px;
      /* padding-bottom: 15px; */
    }
  }
  
