.programs{
    margin: 30px auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.program img{
    width: 100%;
    border-radius: 10px;
    display: block;
}
.program{
    /* flex-basis: 31%; */
    position: relative;
    margin: 10px;
}
.other-article{
    padding: 10px;
}
.caption{
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 15, 152, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    cursor: pointer;
    opacity: 0;
    padding-top: 70%;
    transition: 0.4s;
}
.caption img{
    width: 60px;
    margin-bottom: 10px;
}
.program:hover .caption{
    opacity: 1;
    padding-top: 0;
}
.programs .program .other-article:hover{
    background-color: rgb(124, 124, 124, 0.3);
    border-radius: 10px;
}

.description{
    padding: 10px;
    line-height: 1.4;
}
.description h3{
    color: blue;
    padding-bottom: 15px;
}

.description h4{
    padding-bottom: 10px;
    line-height: 1.4;
}
.description h2{
    padding-bottom: 10px;
}



/* ----------- Media Querry */

@media (max-width: 750px) {
    .programs{
        flex-direction: column;
    }
    .program{
        flex-basis: 100%;
        margin: 20px;
    }
}